<script>
import Horizontal from "./horizontal";
import ax from '../../helpers/restful/service';
import checkTestModal from '../../components/check-test.vue';
import showTestScoreModal from '../../components/show-test-score.vue';
import successModal from '../../components/success.vue';
// import Receipt from '@/components/receipt.vue';
import {
    teacherMainLayoutComputed,
} from "@/state/helpers";
import AlertModal from "@/components/alert-modal.vue";


export default {
    components: {
        Horizontal,
        checkTestModal,
        showTestScoreModal,
        successModal,
        "alert-modal": AlertModal,
        // Receipt,
    },
    data() {
        return {
            uid: JSON.parse(localStorage.getItem('auth.currentUser')).uid || 0,
            currentUser: null,
            loaded: false,
            loaderHeight: window.innerHeight * 0.1,
            isShowCheckTestModal: false,
            isShowTestScoreModal: false,
            isShowSuccessModal: false,
            showReceiptModal: false,

            problems: [],
            answers: [],

            index: 1,
            score: 0,
            pass_percent: 0.6, //60%
            pass_amount_test: 100000,

            way_to_get_document: "thaipost",
            payment: {
                token: process.env.VUE_APP_GB_TOKEN,
            },

            showUnauthorizeModal: false,
            scoreUnderPassModal: false,
            test_expired_time: null,
            text_expired_text: null,
            timeup: false,

            intervalTime: null,

        };
    },
    validations: {

    },
    computed: {
        ...teacherMainLayoutComputed,

    },
    async mounted() {
        document.body.classList.add('authentication-bg');
        this.course_id = this.$route.query.course_id;
        this.uid = JSON.parse(localStorage.getItem('auth.currentUser')).uid;

        console.log(this.course_id);
        console.log(this.uid);

        if(this.course_id && this.uid){
            const resp = await ax.post("/check-status-member", { uid: this.uid, course_id: this.course_id });
            console.log("resp:", resp);
            if (resp.status != 200) {
                alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                return;
            }

            this.isExist = resp.data.is_exist;
            this.isPaid = resp.data.is_paid;
            this.registed_data = resp.data;

            if(this.registed_data.test_started_dt == null && this.registed_data.test_score == null){
                this.showUnauthorizeModal = true;
                return;
            }
            
            console.log("this.registed_data.content_study:", this.registed_data.content_study);

            if(this.isExist && this.isPaid && this.registed_data != null){
                if(this.registed_data.is_study_all_content == 0){
                    this.showUnauthorizeModal = true;
                    return;
                }
                const resp = await ax.post("/get-exam-by-course-id", { course_id: this.course_id });
                console.log("resp exam:", resp);
                if (resp.status != 200) {
                    alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                    return;
                }
                this.course_data = resp.data.course_data;
                this.problems = resp.data.problems;
                this.answers = [];
                this.problems.map((e, i) => this.answers.push({
                    s: i+1,
                    b: ""
                }));

                this.pass_percent = resp.data.course_data.exam_json.percent_pass / 100;
                this.pass_amount_test = this.problems.length * this.pass_percent;

                console.log("this.pass_percent:", this.pass_percent);
                console.log("this.pass_amount_test:", this.pass_amount_test);

                const d = this.registed_data.test_started_dt != null ? new Date(this.registed_data.test_started_dt) : new Date();
                d.setTime(d.getTime() + (parseInt(resp.data.course_data.exam_json.time_test) * 60 * 1000));
                this.test_expired_time = d;
                
                console.log("this.test_expired_time: ", this.test_expired_time);

                this.loaded = true;
            } else {
                this.showUnauthorizeModal = true;
                return;
            }
        } else {
            this.showUnauthorizeModal = true;
            return;
        }
        this.videoPanelHeight = window.innerWidth * 9 / 16;

        this.intervalTime = setInterval(() => this.getRemainingTime(), 1000);
    },
    methods: {
        getRemainingTime(){
            const now = new Date();
            const diff = (this.test_expired_time.getTime() - now.getTime())/1000;
            if(diff <= 0) {
                clearInterval(this.intervalTime);
                this.timeup = true;
                this.text_expired_text = `หมดเวลาแล้ว`;
                this.checkAnswers();
            }
            
            const m = Math.floor((diff / 60));
            const s = Math.floor((diff % 60));
            
            this.text_expired_text = `เหลือเวลา ${m} นาที ${s} วินาที`;
        },
        async getUserInfo() {
            console.log("GetUserInfo");
            console.log("UID:", this.uid);

            const resp = await ax.post("/get-latest-user-info", { uid: this.uid });
            console.log("resp:", resp);

            if (resp.status != 200) {
                return;
            }

            this.currentUser = resp.data;
            console.log("currentUser:", this.currentUser);
            this.loaded = true;
            console.log("loaded:", this.loaded);
        },
        closeCheckTestModal() {
            this.isShowCheckTestModal = false;
            return;
        },
        closeShowTestScoreModal() {
            this.isShowTestScoreModal = false;
            return;
        },
        increaseIndex() {
            this.index++;
        },
        decreaseIndex() {
            this.index--;
        },
        async checkAnswers() {
            console.log("this.answers:", this.answers);
            const r = this.answers.every((e) => e.b != "");
            console.log("Answers all questions:", r);

            if (!r && !this.timeup) {
                this.isShowCheckTestModal = true;
                return;
            }

            const score = this.problems.reduce((total, i) => {
                const [answer] = this.answers.filter(e => e.s == i.s);
                console.log("answer:", answer);
                console.log("total:", total);
                console.log("i.c:", i.c);
                console.log("answer.b:", answer.b);
                return i.c == answer.b ? total + 1 : total;
            }, 0);

            console.log("Score:", score);
            this.score = score;
            this.isShowTestScoreModal = true;
            if(score >= this.pass_amount_test){
                await ax.post('/update-course-member-score', {
                    uid: this.uid,
                    course_id: this.course_id,
                    score: score,
                });
            }
            if(this.timeup){
                await ax.post('/update-course-member-score', {
                    uid: this.uid,
                    course_id: this.course_id,
                    score: null,
                });
                setTimeout(() => this.goTo('/course-learning?course_id='+this.course_id), 7000);
            }
            return;
        },
        async saveScore() {
            this.isShowTestScoreModal = false;
            console.log("save");
            if(this.score < this.pass_amount_test){
                this.scoreUnderPassModal = true;
                return;
            }
            const r = await ax.post('/update-course-member-score', {
                uid: this.uid,
                course_id: this.course_id,
                score: this.score,
            });
            console.log("response:", r);
            this.isShowSuccessModal = true;
            window.location.reload();
            return;
        },
        async saveData() {
            const r = await ax.post('/update-score-way', {
                uid: this.uid,
                way_to_get_document: this.way_to_get_document,
            });
            console.log("response:", r);
            if (this.way_to_get_document == "thaipost") {
                this.payAgain();
                return;
            }
            this.isShowSuccessModal = true;
            return;
        },
        async payAgain() {
            const r = await ax.post('/course-test-pay-again', {
                uid: this.uid,
            });
            console.log("response:", r);
            const responseData = r.data;

            if (responseData.resultCode != "00") {
                const form = document.createElement("form");
                form.method = "POST";
                form.action = "https://api.gbprimepay.com/gbp/gateway/qrcode";
                form.enctype = "application/x-www-form-urlencoded";
                form.target = "_blank";

                const token = document.createElement("input");
                token.type = "hidden";
                token.value = this.payment.token;
                token.name = "token";
                form.appendChild(token);

                let amount = document.createElement("input");
                amount.type = "hidden";
                amount.value = "130";
                amount.name = "amount";
                form.appendChild(amount);

                let referenceNo = document.createElement("input");
                referenceNo.type = "hidden";
                referenceNo.value = responseData.referenceNo;
                referenceNo.name = "referenceNo";
                form.appendChild(referenceNo);

                let backgroundUrl = document.createElement("input");
                backgroundUrl.type = "hidden";
                backgroundUrl.value = responseData.backgroundUrl;
                backgroundUrl.name = "backgroundUrl";
                form.appendChild(backgroundUrl);

                document.body.appendChild(form);
                form.submit();
            }
            return;
        },
        goTo(path){
            this.$router.push({ path: path });
            return;
        },
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType">
            <div class="container" v-if="loaded && registed_data.test_score != null && registed_data.test_score >= pass_amount_test">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h2 class="text-center">ท่านได้ทำการทดสอบเรียบร้อยแล้ว คะแนนของท่าน คือ</h2>
                                <h1 class="text-center">{{  registed_data.test_score }} / {{ problems.length }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" v-if="loaded && registed_data.test_score == null">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="mb-3 text-center">แบบทดสอบ จำนวน {{ problems.length }} ข้อ</h4>
                                <h4 class="mb-3 text-center">เวลาในการทำ {{ course_data.exam_json.time_test }} นาที</h4>
                                <h5 class="text-end">{{ text_expired_text }}</h5>

                                <h5 class="mb-3">
                                    ข้อ. {{ problems.filter((e) => e.s == index)[0].s }}<br />
                                    {{ problems.filter((e) => e.s == index)[0].a }} ?
                                </h5>

                                <b-input-group>
                                    <b-form-group v-slot="">
                                        <b-form-radio v-model="answers.filter((e) => e.s == index)[0].b" value="b1"><span
                                                class="ms-1"></span>ก. {{ problems.filter((e) => e.s ==
                                                    index)[0].b.b1 }}</b-form-radio>
                                        <b-form-radio v-model="answers.filter((e) => e.s == index)[0].b" value="b2"><span
                                                class="ms-1"></span>ข. {{ problems.filter((e) => e.s ==
                                                    index)[0].b.b2 }}</b-form-radio>
                                        <b-form-radio v-model="answers.filter((e) => e.s == index)[0].b" value="b3"><span
                                                class="ms-1"></span>ค. {{ problems.filter((e) => e.s ==
                                                    index)[0].b.b3 }}</b-form-radio>
                                        <b-form-radio v-model="answers.filter((e) => e.s == index)[0].b" value="b4"><span
                                                class="ms-1"></span>ง. {{ problems.filter((e) => e.s ==
                                                    index)[0].b.b4 }}</b-form-radio>
                                    </b-form-group>

                                </b-input-group>
                            </div>
                            <div class="row my-3 card-body">
                                <div class="mt-2 col-12 col-md-6 col-lg-6 col-xl-6 d-grid">
                                    <b-button block size="lg" type="button" variant="primary" class="w-lg"
                                        :disabled="index <= 1" @click="decreaseIndex">ข้อก่อนหน้า</b-button>
                                </div>
                                <div class="mt-2 col-12 col-md-6 col-lg-6 col-xl-6 d-grid">
                                    <b-button block size="lg" type="button" variant="primary" class="w-lg"
                                        :disabled="index >= problems.length" @click="increaseIndex">ข้อต่อไป</b-button>
                                </div>
                            </div>
                            <div class="card-body text-center d-block">
                                <b-form @submit.prevent="checkAnswers()">
                                    <b-button size="lg" type="submit" variant="success" class="w-lg">ตรวจข้อสอบ</b-button>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Horizontal>
        <successModal :isShow="isShowSuccessModal" v-on:close="isShowSuccessModal = false">
        </successModal>
        <checkTestModal :isShow="isShowCheckTestModal" v-on:close="closeCheckTestModal">
        </checkTestModal>
        <showTestScoreModal :isShow="isShowTestScoreModal" :score="score" :max_score="problems.length"
            :pass_percent="pass_percent" v-on:close="closeShowTestScoreModal" v-on:save="saveScore">
        </showTestScoreModal>

        <alert-modal :isShow="showUnauthorizeModal" title="คุณไม่ได้รับอนุญาตให้เข้าถึงเนื้อหา"
            text="ระบบจะส่งท่านกลับไปยังหน้าหลัก" v-on:close="goTo('/course-registed');"></alert-modal>
        
        <alert-modal :isShow="scoreUnderPassModal" title="คะแนนไม่ผ่านเกณฑ์"
            text="ท่านจะต้อง ทำแบบทดสอบใหม้อีกครั้ง ระบบจะส่งท่านกลับไปยังหน้าหลัก" v-on:close="goTo('/course-registed');"></alert-modal>

        <!-- <b-modal v-if="loaded && registed_data.resultCode == '00'" size="xl" v-model="showReceiptModal" no-close-on-esc
            no-close-on-backdrop hide-header-close centered>
            <Receipt v-if="registed_data != null" :receipt_no="registed_data.id"
                :reference_no="registed_data.referenceNo" :created_dt="registed_data.created_dt"
                :firstname="registed_data.firstname || '-'" :lastname="registed_data.lastname || '-'"
                :phone="registed_data.phone || '-'"
                :identity_no="registed_data.identity_number || '-'" receipt_name="ค่าดำเนินการจัดส่งเอกสาร"
                :amount="registed_data.amount"
                :fee="registed_data.fee ? registed_data.fee.toFixed(2) : registed_data.fee"
                :total="registed_data.amount && registed_data.fee ? (registed_data.amount + registed_data.fee).toFixed(2) : (registed_data.amount + registed_data.fee)">
                ></Receipt>
            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="" size="xl" @click="showReceiptModal = !showReceiptModal;">
                        <h3 class="text-white">ปิด</h3>
                    </b-button>
                </div>
            </template>
    </b-modal> -->
</div></template>
